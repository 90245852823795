import { H2, Intent, ProgressBar } from '@blueprintjs/core';
import * as React from 'react';
import { Tooltip } from 'vet-bones/components';

import { Loading } from 'src/ui/components/Loading';
import {
  API_CREDIT_PURCHASED,
  API_CREDIT_REMAINING,
  API_CREDIT_USED,
} from 'src/ui/constants/testIds';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import { useGetVoiceboxCreditQuery } from 'src/ui/graph/types';
import * as copy from 'src/ui/templates/copy';

export const VoiceboxApiUsage: React.VFC = () => {
  const { data, isLoading } = useGetVoiceboxCreditQuery(graphQLClient);

  const creditsPurchased = data?.getVoiceboxCredit?.limit || 0;
  const creditsUsed = data?.getVoiceboxCredit?.usage || 0;
  const creditsRemaining = creditsPurchased - creditsUsed;
  const percentageUsed = ((creditsUsed / creditsPurchased) * 100).toFixed(1);
  const percentageRemaining = (
    (creditsRemaining / creditsPurchased) *
    100
  ).toFixed(1);

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div className="sd-table">
      <div className="sd-table__header">
        <H2>{copy.components.manageApiTokens.voiceboxCreditUsage.header}</H2>
      </div>
      <hr className="divider" />
      <p>
        <strong>
          {copy.components.manageApiTokens.voiceboxCreditUsage.purchased}
          {': '}
        </strong>
        <span data-testid={API_CREDIT_PURCHASED}>{creditsPurchased}</span>
      </p>
      <p>
        <strong>
          {copy.components.manageApiTokens.voiceboxCreditUsage.usedCredits}
          {': '}
        </strong>
        <span data-testid={API_CREDIT_USED}>{creditsUsed}</span>
      </p>
      <p>
        <strong>
          {copy.components.manageApiTokens.voiceboxCreditUsage.remaining}
          {': '}
        </strong>
        <span data-testid={API_CREDIT_REMAINING}>{creditsRemaining}</span>
      </p>
      <Tooltip
        content={
          <>
            <p>
              {creditsUsed}{' '}
              {copy.components.manageApiTokens.voiceboxCreditUsage.usedCredits}{' '}
              {percentageUsed}%
            </p>
            <p>
              {creditsRemaining}{' '}
              {copy.components.manageApiTokens.voiceboxCreditUsage.remaining}{' '}
              {percentageRemaining}%
            </p>
          </>
        }
      >
        <ProgressBar
          value={creditsUsed / creditsPurchased}
          animate={false}
          stripes={false}
          intent={Intent.PRIMARY}
        />
      </Tooltip>
    </div>
  );
};
