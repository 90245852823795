import { Intent } from '@blueprintjs/core';
import * as React from 'react';
import {
  VoiceboxConversationContext,
  VoiceboxSchemaContext,
  VoiceboxSettingsContext,
  useVoiceboxSettingsValue,
} from 'vet-bones/components/molecules/Voicebox';
import { noop } from 'vet-bones/utils';
import { useDidValueChange } from 'vet-bones/utils/hooks';
import { usePortalVoicebox, useStaticVoicebox } from 'vet-bones/utils/voicebox';

import { useAppDispatch } from 'src/ui/app/hooks';
import {
  VoiceboxContext,
  VoiceboxContextData,
  useVoiceboxContextValue,
} from 'src/ui/containers/voicebox/VoiceboxContext';
import { queueMessage } from 'src/ui/features/notifications';
import { useProfile } from 'src/ui/hooks/connection';
import * as copy from 'src/ui/templates/copy';

export const VoiceboxContextWrapper: React.VFC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const voiceboxContext = useVoiceboxContextValue();

  const { data: profileData } = useProfile();
  const voiceboxData = voiceboxContext?.pageData || voiceboxContext?.drawerData;

  let body = <>{children}</>;
  if (voiceboxData) {
    const isStaticVoicebox = Boolean(profileData?.profile?.is_static_voicebox);
    body = isStaticVoicebox ? (
      <StaticVoiceboxContext voiceboxData={voiceboxData}>
        {children}
      </StaticVoiceboxContext>
    ) : (
      <PortalVoiceboxContext voiceboxData={voiceboxData}>
        {children}
      </PortalVoiceboxContext>
    );
  }

  return (
    <VoiceboxContext.Provider value={voiceboxContext}>
      {body}
    </VoiceboxContext.Provider>
  );
};

export const PortalVoiceboxContext: React.VFC<{
  children: React.ReactNode;
  voiceboxData: VoiceboxContextData;
}> = ({ children, voiceboxData }) => {
  const {
    initialConversationId,
    newConversationKey,
    onConversationChange,
    schema,
  } = voiceboxData;
  const dispatch = useAppDispatch();

  const onFeedbackError = React.useCallback(
    /* istanbul ignore next */
    (message: string) => {
      dispatch(
        queueMessage({
          intent: Intent.DANGER,
          message,
        })
      );
    },
    [dispatch]
  );

  const onDownloadCsvError = React.useCallback(
    /* istanbul ignore next */
    (err: string) => {
      dispatch(
        queueMessage({
          intent: Intent.DANGER,
          message: copy.components.voicebox.downloadCsvError(err),
        })
      );
    },
    [dispatch]
  );

  const onDownloadCsvSuccess = React.useCallback(
    /* istanbul ignore next */
    (_, fileName: string) => {
      dispatch(
        queueMessage({
          intent: Intent.SUCCESS,
          message: copy.components.voicebox.downloadCsvSuccess(fileName),
        })
      );
    },
    [dispatch]
  );

  const conversation = usePortalVoicebox({
    id: newConversationKey,
    initialConversationId,
    onDownloadCsvError,
    onDownloadCsvSuccess,
    onFeedbackError,
    onMessageError: noop,
    onMessageSuccess: noop,
    schema,
  });
  const { conversationId } = conversation;
  const didConversationIdChange = useDidValueChange(conversationId);
  React.useEffect(() => {
    if (didConversationIdChange) {
      onConversationChange?.(conversationId);
    }
  }, [conversationId, didConversationIdChange, onConversationChange]);
  const settings = useVoiceboxSettingsValue({
    id: newConversationKey,
    conversation,
    schema,
  });

  return (
    <VoiceboxSchemaContext.Provider value={schema}>
      <VoiceboxConversationContext.Provider value={conversation}>
        <VoiceboxSettingsContext.Provider value={settings}>
          {children}
        </VoiceboxSettingsContext.Provider>
      </VoiceboxConversationContext.Provider>
    </VoiceboxSchemaContext.Provider>
  );
};

export const StaticVoiceboxContext: React.VFC<{
  children: React.ReactNode;
  voiceboxData: VoiceboxContextData;
}> = ({ children, voiceboxData }) => {
  const {
    initialConversationId,
    newConversationKey,
    onConversationChange,
    schema,
  } = voiceboxData;
  const conversation = useStaticVoicebox({
    id: newConversationKey,
    initialConversationId,
    cloudResponseConfig: {},
    schema,
  });
  const { conversationId } = conversation;
  const didConversationIdChange = useDidValueChange(conversationId);
  React.useEffect(() => {
    if (didConversationIdChange) {
      onConversationChange?.(conversationId);
    }
  }, [conversationId, didConversationIdChange, onConversationChange]);
  const settings = useVoiceboxSettingsValue({
    id: newConversationKey,
    conversation,
    schema,
  });

  return (
    <VoiceboxSchemaContext.Provider value={schema}>
      <VoiceboxConversationContext.Provider value={conversation}>
        <VoiceboxSettingsContext.Provider value={settings}>
          {children}
        </VoiceboxSettingsContext.Provider>
      </VoiceboxConversationContext.Provider>
    </VoiceboxSchemaContext.Provider>
  );
};
