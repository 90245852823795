import * as React from 'react';
import { useQueryClient } from 'react-query';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import { AnyFunc } from 'src/ui/constants/types';
import { ConnectionForm } from 'src/ui/containers/dialogs/ConnectionForm';
import { makeSubmitAction } from 'src/ui/containers/dialogs/makeSubmitAction';
import {
  Connection,
  ConnectionStatus,
  clearEditIndex,
  setConnectionStatus,
} from 'src/ui/features/connection';
import { closedDialog } from 'src/ui/features/ui';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  EditConnectionInput,
  useEditConnectionMutation,
  useSavedConnectionsQuery,
} from 'src/ui/graph/types';
import {
  useCurrentConnection,
  useVerifyConnection,
} from 'src/ui/hooks/connection';
import { useOnError, useOnMutate } from 'src/ui/hooks/graph';
import { editIndexSelector } from 'src/ui/selectors/connection';
import * as copy from 'src/ui/templates/copy';

interface EditConnectionFormProps {
  showSSO?: boolean;
}

export const EditConnectionForm: React.VFC<EditConnectionFormProps> = ({
  showSSO = true,
}) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const onError = useOnError();
  const onMutate = useOnMutate();
  const { data, isLoading } = useSavedConnectionsQuery(graphQLClient);
  const editIndex = useAppSelector(editIndexSelector);
  const { connection } = useCurrentConnection(editIndex.toString());
  const { isLoading: isValidating, mutate: validate } = useVerifyConnection();

  const {
    isLoading: isEditingConnection,
    mutate: editConnection,
  } = useEditConnectionMutation(graphQLClient, {
    onMutate,
    onError,
    onSuccess: () => {
      dispatch(closedDialog());
      dispatch(clearEditIndex());
      queryClient.invalidateQueries(useSavedConnectionsQuery.getKey());
    },
  });

  const [savedNames, setSavedNames] = React.useState<string[]>([]);
  const [editName, setEditName] = React.useState<string | null>(null);
  const isWorking = isLoading || isValidating || isEditingConnection;

  React.useEffect(() => {
    /* istanbul ignore next: no need to test defensive code */
    if (!data || !data.listConnections) {
      return;
    }
    if (connection) {
      setEditName(connection.name);
    }
    setSavedNames(data.listConnections.map((conn) => conn?.name || 'unknown'));
  }, [data, connection]);

  const handleEdit = (conn: Connection, reset: AnyFunc) => {
    const testConn = {
      ...conn,
      token: conn.password ? '' : conn.token,
    };
    validate(testConn, {
      onError,
      onSuccess: (data) => {
        // We need to add in the connection ID to satisfy the edit input
        // and not duplicate all the verification logic with different types.
        const editConnectionInput: EditConnectionInput = {
          id: conn.id as string,
          ...data,
        };
        dispatch(setConnectionStatus(ConnectionStatus.PENDING));
        editConnection({ input: editConnectionInput }, { onSuccess: reset });
      },
    });
  };

  const submitDisabled = (conn: Connection) => submitDisabledText(conn) !== '';

  const submitDisabledText = (conn: Connection): string => {
    if (!conn.useSSO && !conn.useBrowserAuth && !conn.username) {
      return copy.components.connectionDialog.formValidation.usernameRequired;
    }

    const usernameChanged = conn.username !== connection?.username;
    if (
      usernameChanged &&
      !conn.useSSO &&
      !conn.useBrowserAuth &&
      conn.username &&
      !conn.password
    ) {
      return copy.components.connectionDialog.formValidation.passwordRequired;
    }

    if (!conn.endpoint) {
      return copy.components.connectionDialog.formValidation.endpointRequired;
    }

    if (conn.endpoint && !conn.endpoint.startsWith('http')) {
      return copy.components.connectionDialog.formValidation.invalidEndpoint;
    }

    const nameChanged = conn.name !== editName;
    if (nameChanged && savedNames.includes(conn.name)) {
      return copy.components.connectionDialog.formValidation.nameUniq;
    }

    if (!conn.name) {
      return copy.components.connectionDialog.formValidation.nameRequired;
    }

    return '';
  };

  return connection ? (
    <ConnectionForm
      actions={[
        makeSubmitAction(
          copy.components.connectionDialog.edit.submit,
          isWorking
        ),
      ]}
      connection={connection}
      onSubmit={handleEdit}
      submitDisabled={submitDisabled}
      submitTooltip={submitDisabledText}
      showSSO={showSSO}
    />
  ) : null;
};
