import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { VoiceboxChatPage } from 'vet-bones/components/molecules/Voicebox';
import { isEqual } from 'vet-bones/utils';
import { useDidValueChange } from 'vet-bones/utils/hooks';

import { useAppDispatch } from 'src/ui/app/hooks';
import { Loading } from 'src/ui/components/Loading';
import { VOICEBOX_PAGE_DRAWER_BODY_CONTAINER } from 'src/ui/constants/testIds';
import { useVoiceboxConversations } from 'src/ui/containers/voicebox/aside/useVoiceboxConversations';
import { useVoiceboxContext } from 'src/ui/containers/voicebox/VoiceboxContext';
import { useProfile } from 'src/ui/hooks/connection';
import { useQueryParams } from 'src/ui/hooks/query';
import {
  DEFAULT_VOICEBOX_NAMED_GRAPH,
  useNotAllowedVoiceboxPage,
} from 'src/ui/hooks/voicebox';

type TParams = { id: string; conversation?: string };

export const VoiceboxPage: React.VFC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const queryParams = useQueryParams();

  const params = useParams<TParams>();
  const conversationId = params.conversation || '';
  const connectionIdx = Number(params.id) || 0;

  const { data: profileData } = useProfile();
  const voiceboxContext = useVoiceboxContext();

  const { setInitialSettings } = voiceboxContext?.pageData || {};
  React.useEffect(() => {
    const databaseId = queryParams.get('db') || '';
    const graphs = queryParams.get('graph') || '';
    const model = queryParams.get('model') || '';

    if (databaseId || graphs || model) {
      setInitialSettings?.((prev) => {
        const next = {
          databaseId,
          model,
          namedGraphs: graphs
            ? graphs.split(',')
            : [DEFAULT_VOICEBOX_NAMED_GRAPH],
          reasoning: true,
        };
        return isEqual(prev, next) ? prev : next;
      });
    }
  }, [history, queryParams, setInitialSettings]);

  const { loading: isLoadingPage, notAllowed } = useNotAllowedVoiceboxPage(
    params.id
  );
  const didIsLoadingPageChange = useDidValueChange(isLoadingPage);
  const needsRedirect =
    (voiceboxContext &&
      !voiceboxContext.isLoadingConnection &&
      !voiceboxContext.connection) ||
    (didIsLoadingPageChange && !isLoadingPage && notAllowed);

  React.useEffect(() => {
    if (needsRedirect) {
      console.warn(`notAllowed true, redirecting to /u/${connectionIdx}/`);
      history.push(`/u/${connectionIdx}/`);
    }
  }, [connectionIdx, history, needsRedirect]);

  const conversations = useVoiceboxConversations(
    voiceboxContext?.connection?.id || ''
  );
  const didFetchingConversationChange = useDidValueChange(
    conversations.isFetching
  );
  const didNotFindConversation =
    didFetchingConversationChange &&
    !conversations.isFetching &&
    !profileData?.profile?.is_static_voicebox &&
    !conversations.items.find(
      (conversation) => conversation.value === conversationId
    ) &&
    conversationId;
  React.useEffect(() => {
    if (didNotFindConversation) {
      history.push(`/u/${connectionIdx}/voicebox/`);
    }
  }, [connectionIdx, didNotFindConversation, dispatch, history]);

  if (
    notAllowed ||
    !voiceboxContext ||
    !voiceboxContext.connection ||
    !voiceboxContext.pageData
  ) {
    return null;
  }

  const body = isLoadingPage ? (
    <Loading />
  ) : (
    <div
      className="sd-voicebox-container"
      data-testid={VOICEBOX_PAGE_DRAWER_BODY_CONTAINER}
    >
      <VoiceboxChatPage />
    </div>
  );

  return (
    <div className="sd-voicebox-page">
      <div className="sd-voicebox-page-body">{body}</div>
    </div>
  );
};
