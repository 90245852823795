import * as React from 'react';
import { useQuery } from 'react-query';
import { useRouteMatch } from 'react-router-dom';
import { server as StardogServer } from 'stardog';
import { BaseConnection, getStardogConnection } from 'vet-bones/utils';

import {
  DEMO_CONNECTION_INDEX,
  DEMO_KIT_DB_DEFAULT,
} from 'src/ui/features/connection';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  useGetConnectionByIndexQuery,
  useMarketplaceSettingsQuery,
} from 'src/ui/graph/types';
import { useProfile } from 'src/ui/hooks/connection';
import {
  marketplaceSettingsToDbOptions,
  useModuleMeta,
} from 'src/ui/hooks/modules';
import { runWithRefetchConnectionToken } from 'src/ui/hooks/refetchConnectionToken';

export const DEFAULT_CATALOG_DATABASE_ID = 'catalog';
export const DEFAULT_VOICEBOX_NAMED_GRAPH = 'tag:stardog:api:context:local';

type VoiceboxPageConnectionData = {
  catalogDatabaseId: string;
  connectionIndex: string;
  conversationId?: string;
};

type VoiceboxDrawerConnectionData = {
  catalogDatabaseId: string;
  connectionIndex: string;
  databaseId: string;
  model: string;
  namedGraphs: string[];
};

export const useIsVoiceboxEnabledServer = (connectionIndex: string) => {
  const { data: connection } = useGetConnectionByIndexQuery(
    graphQLClient,
    { index: Number(connectionIndex) },
    { enabled: Boolean(connectionIndex) }
  );

  const stardogConnection = connection?.connection
    ? getStardogConnection(connection.connection as BaseConnection)
    : null;

  return useQuery<boolean | null>(
    `useIsVoiceboxEnabledServer-${stardogConnection ? connectionIndex : null}`,
    async () => {
      if (!stardogConnection) {
        return null;
      }

      try {
        const response = await runWithRefetchConnectionToken(
          stardogConnection,
          Number(connectionIndex),
          (stardogConn) =>
            StardogServer.status(stardogConn, {
              databases: false,
            })
        );
        if (!response.ok) {
          console.error(
            `problem requesting useIsVoiceboxEnabledServer: ${
              response.statusText || String(response.status)
            }`
          );
          return false;
        }
        return Boolean(response.body?.['dbms.license.voicebox.limit']?.value);
      } catch (err) {
        console.error(`problem requesting useIsVoiceboxEnabledServer: ${err}`);
        return false;
      }
    },
    { initialData: null }
  );
};

const useCatalogDatabaseId = (connectionIndex: string) => {
  const { data: connection } = useGetConnectionByIndexQuery(
    graphQLClient,
    { index: Number(connectionIndex) },
    { enabled: Boolean(connectionIndex) }
  );

  const stardogConnection = connection?.connection
    ? getStardogConnection(connection.connection as BaseConnection)
    : null;

  return useQuery<string | null>(
    `useCatalogDatabaseId-${stardogConnection ? connectionIndex : null}`,
    async () => {
      if (!stardogConnection) {
        return null;
      }

      try {
        const response = await runWithRefetchConnectionToken(
          stardogConnection,
          Number(connectionIndex),
          (stardogConn) =>
            StardogServer.properties(stardogConn, ['catalog.database'])
        );
        if (!response.ok) {
          console.error(
            `problem requesting useCatalogDatabaseId: ${
              response.statusText || String(response.status)
            }`
          );
          return DEFAULT_CATALOG_DATABASE_ID;
        }

        return (
          response.body?.['catalog.database'] || DEFAULT_CATALOG_DATABASE_ID
        );
      } catch (err) {
        console.error(`problem requesting useCatalogDatabaseId: ${err}`);
        return DEFAULT_CATALOG_DATABASE_ID;
      }
    }
  );
};

const useVoiceboxPageData = (
  connectionIndex: string,
  conversationId?: string
): VoiceboxPageConnectionData | null => {
  const maybeCatalogDatabaseId = useCatalogDatabaseId(connectionIndex);
  const catalogDatabaseId =
    maybeCatalogDatabaseId.data || DEFAULT_CATALOG_DATABASE_ID;

  return React.useMemo(
    () => ({ catalogDatabaseId, connectionIndex, conversationId }),
    [catalogDatabaseId, connectionIndex, conversationId]
  );
};

export const useVoiceboxPageConnectionData = (): VoiceboxPageConnectionData | null => {
  const pageMatch = useRouteMatch<{ id: string; conversation?: string }>({
    exact: true,
    path: ['/u/:id(\\d+)/voicebox/:conversation?'],
  });
  const isVoiceboxPage = Boolean(pageMatch);

  const isVoiceboxEnabledServer = useIsVoiceboxEnabledServer(
    pageMatch?.params.id || ''
  );

  const { data: profileData } = useProfile();
  const isVoiceboxEnabledUser = profileData?.profile?.is_voicebox_enabled;

  const pageConnectionData = useVoiceboxPageData(
    pageMatch?.params.id || '',
    pageMatch?.params.conversation
  );

  return React.useMemo(() => {
    if (
      isVoiceboxPage &&
      isVoiceboxEnabledUser &&
      isVoiceboxEnabledServer.data
    ) {
      return pageConnectionData;
    }

    return null;
  }, [
    isVoiceboxEnabledServer,
    isVoiceboxEnabledUser,
    isVoiceboxPage,
    pageConnectionData,
  ]);
};

const useVoiceboxDrawerData = (
  id: string
): VoiceboxDrawerConnectionData | null => {
  const { data: marketplaceSettingsData } = useMarketplaceSettingsQuery(
    graphQLClient
  );
  const marketplaceConfig = marketplaceSettingsToDbOptions(
    marketplaceSettingsData?.marketplaceSettings
  );
  const { data: moduleData } = useModuleMeta(id || '', marketplaceConfig);

  const connectionIndex = DEMO_CONNECTION_INDEX.toString();

  const maybeCatalogDatabaseId = useCatalogDatabaseId(connectionIndex);
  const catalogDatabaseId =
    maybeCatalogDatabaseId.data || DEFAULT_CATALOG_DATABASE_ID;

  const isVoiceboxKitEnabled = moduleData?.moduleMeta?.voiceboxEnabled;

  const databaseId =
    moduleData?.module?.databaseId ||
    marketplaceConfig?.database ||
    DEMO_KIT_DB_DEFAULT;

  const model = moduleData?.module?.schemaName || '';

  const namedGraph = moduleData?.module?.alias || DEFAULT_VOICEBOX_NAMED_GRAPH;

  const data = React.useMemo(
    () =>
      isVoiceboxKitEnabled
        ? {
            catalogDatabaseId,
            connectionIndex,
            databaseId,
            model,
            namedGraphs: [namedGraph],
          }
        : null,
    [
      catalogDatabaseId,
      connectionIndex,
      databaseId,
      isVoiceboxKitEnabled,
      model,
      namedGraph,
    ]
  );

  return data;
};

export const useVoiceboxDrawerConnectionData = (): VoiceboxDrawerConnectionData | null => {
  const { data: profileData } = useProfile();
  const isVoiceboxEnabledUser = profileData?.profile?.is_voicebox_enabled;

  const kitsMatch = useRouteMatch<{ id: string }>({
    exact: true,
    path: ['/kits/:id([\\w:\\d\\-\\.%]+)'],
  });
  const isKitsPage = Boolean(kitsMatch);
  const kitsConnectionData = useVoiceboxDrawerData(kitsMatch?.params.id || '');

  return React.useMemo(() => {
    if (isKitsPage && isVoiceboxEnabledUser) {
      return kitsConnectionData;
    }

    return null;
  }, [isKitsPage, isVoiceboxEnabledUser, kitsConnectionData]);
};

/** returns true if we know for sure the user shouldn't access the VoiceboxPage */
export const useNotAllowedVoiceboxPage = (
  connectionIndex: string
): { loading: boolean; notAllowed: boolean } => {
  const isVoiceboxEnabled = useIsVoiceboxEnabledServer(connectionIndex);

  return React.useMemo(() => {
    if (isVoiceboxEnabled.isLoading || isVoiceboxEnabled.data === null) {
      return {
        loading: true,
        notAllowed: false,
      };
    }

    return {
      loading: false,
      notAllowed: !isVoiceboxEnabled.data,
    };
  }, [isVoiceboxEnabled]);
};
