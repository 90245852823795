import {
  ApiKey,
  Cookie,
  IdManagement,
  Logout,
  Purchase,
  TrashCan,
  User,
} from '@carbon/icons-react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import {
  NavbarMenuItem,
  NavbarMenuItems,
  NavbarSchema,
} from 'vet-bones/components';
import { cookiebotAgent } from 'vet-bones/utils';

import { useAppDispatch } from 'src/ui/app/hooks';
import {
  NAVBAR_AVATAR_MENU,
  NAVBAR_AVATAR_MENU_ITEM,
} from 'src/ui/constants/testIds';
import { DialogType, openedDialog } from 'src/ui/features/ui';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import { useGetStripeSessionUrlMutation } from 'src/ui/graph/types';
import { useProfile } from 'src/ui/hooks/connection';
import * as copy from 'src/ui/templates/copy';
import { redirectToUrl } from 'src/ui/utils/window';

export const usePortalNavbarAvatarSchema = (): NonNullable<
  NavbarSchema['footer']
>['avatar'] => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { data: profileData } = useProfile();
  const profile = profileData?.profile;

  const { mutate: mutateStripeSession } = useGetStripeSessionUrlMutation(
    graphQLClient,
    {
      onSuccess: (data) => {
        redirectToUrl(data?.getStripeSessionUrl?.url || '/');
      },
    }
  );

  const handleUpdateProfile = React.useCallback(() => {
    dispatch(openedDialog({ type: DialogType.UPDATE_PROFILE }));
  }, [dispatch]);

  const handleManageBilling = React.useCallback(() => {
    mutateStripeSession({});
  }, [mutateStripeSession]);

  const handleManageApiKeys = React.useCallback(() => {
    history.push('/keys');
  }, [history]);

  const handleLogout = React.useCallback(() => {
    redirectToUrl('/auth/logout');
  }, []);

  const handleEmployeeHome = React.useCallback(() => {
    history.push('/internal');
  }, [history]);

  const handleDeleteAccount = React.useCallback(() => {
    dispatch(openedDialog({ type: DialogType.DELETE_ACCOUNT }));
  }, [dispatch]);

  return React.useMemo(() => {
    if (!profile || !profile.is_authenticated) {
      return undefined;
    }

    const firstName = profile.first_name || profile.username || '';
    const lastName = profile.last_name || '';

    const items: NavbarMenuItem[] = [
      {
        icon: User,
        label: copy.components.navbar.avatar.menuItems.updateProfile,
        onClick: handleUpdateProfile,
        testId: NAVBAR_AVATAR_MENU_ITEM('update-profile'),
      },
    ];

    if (profile.stripe_customer?.customer_id) {
      items.push({
        icon: Purchase,
        label: copy.components.navbar.avatar.menuItems.manageBilling,
        onClick: handleManageBilling,
        testId: NAVBAR_AVATAR_MENU_ITEM('manage-billing'),
      });
    }

    if (profile.is_voicebox_api_access_enabled) {
      items.push({
        icon: ApiKey,
        label: copy.components.navbar.avatar.menuItems.manageApiTokens,
        onClick: handleManageApiKeys,
        testId: NAVBAR_AVATAR_MENU_ITEM('manage-api-tokens'),
      });
    }

    if (cookiebotAgent.loaded) {
      items.push({
        icon: Cookie,
        label: copy.components.navbar.avatar.menuItems.updateCookieConsent,
        onClick: () => cookiebotAgent.openDialog(),
        testId: NAVBAR_AVATAR_MENU_ITEM('update-cookie-consent'),
      });
    }

    items.push({
      icon: Logout,
      label: copy.components.navbar.avatar.menuItems.logout,
      onClick: handleLogout,
      testId: NAVBAR_AVATAR_MENU_ITEM('logout'),
    });

    const menuItems: NavbarMenuItems = [{ items }];

    if (profile.is_staff) {
      menuItems.push({
        items: [
          {
            icon: IdManagement,
            label: copy.components.navbar.avatar.menuItems.employeeHome,
            onClick: handleEmployeeHome,
            testId: NAVBAR_AVATAR_MENU_ITEM('employee-home'),
          },
        ],
      });
    }

    menuItems.push({
      items: [
        {
          icon: TrashCan,
          label: copy.components.navbar.avatar.menuItems.deleteAccount,
          onClick: handleDeleteAccount,
          testId: NAVBAR_AVATAR_MENU_ITEM('delete-account'),
        },
      ],
    });

    return {
      description: '',
      label: `${firstName} ${lastName}`,
      menuItems,
      testId: NAVBAR_AVATAR_MENU,
      text: `${firstName.slice(0, 1)}${lastName.slice(0, 1)}`,
    };
  }, [
    handleDeleteAccount,
    handleEmployeeHome,
    handleLogout,
    handleManageApiKeys,
    handleManageBilling,
    handleUpdateProfile,
    profile,
  ]);
};
