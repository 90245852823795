import * as React from 'react';

import { Messages } from 'src/ui/components/Messages';
import { Notifications } from 'src/ui/components/Notifications';
import { ConnectionDialog } from 'src/ui/containers/dialogs/ConnectionDialog';
import { CreateApiTokenDialog } from 'src/ui/containers/dialogs/CreateApiTokenDialog';
import { CreateProfileDialog } from 'src/ui/containers/dialogs/CreateProfile/CreateProfileDialog';
import { CreateVoiceboxAppDialog } from 'src/ui/containers/dialogs/CreateVoiceboxAppDialog';
import { DeleteAccountDialog } from 'src/ui/containers/dialogs/DeleteAccountDialog';
import { UnsupportedBrowserDialog } from 'src/ui/containers/dialogs/UnsupportedBrowserDialog';
import { UpdateProfileDialog } from 'src/ui/containers/dialogs/UpdateProfile/UpdateProfileDialog';
import { UpdateVoiceboxAppDialog } from 'src/ui/containers/dialogs/UpdateVoiceboxAppDialog';
import { UpgradePlanDialog } from 'src/ui/containers/dialogs/UpgradePlanDialog';
import { VerifyEmailDialog } from 'src/ui/containers/dialogs/VerifyEmailDialog';
import { PartnerConnectionDialog } from 'src/ui/containers/partner/databricks/PartnerConnectionDialog';
import { Voicebox } from 'src/ui/containers/voicebox/Voicebox';
import { VoiceboxContextWrapper } from 'src/ui/containers/voicebox/VoiceboxContextWrapper';

export const AppDialogs: React.VFC = () => {
  return (
    <>
      <Notifications />
      <Messages />
      <UnsupportedBrowserDialog />
      <CreateProfileDialog />
      <UpdateProfileDialog />
      <ConnectionDialog />
      <DeleteAccountDialog />
      <PartnerConnectionDialog />
      <UpgradePlanDialog />
      <VerifyEmailDialog />
      <CreateApiTokenDialog />
      <CreateVoiceboxAppDialog />
      <UpdateVoiceboxAppDialog />
      <VoiceboxContextWrapper>
        <Voicebox />
      </VoiceboxContextWrapper>
    </>
  );
};
