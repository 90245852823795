import * as React from 'react';
import { VoiceboxDrawer } from 'vet-bones/components/molecules/Voicebox';

import { useVoiceboxContext } from 'src/ui/containers/voicebox/VoiceboxContext';

export const Voicebox: React.VFC = () => {
  const voiceboxContext = useVoiceboxContext();
  if (
    !voiceboxContext ||
    !voiceboxContext.connection ||
    !voiceboxContext.drawerData
  ) {
    return null;
  }

  return <VoiceboxDrawer />;
};
