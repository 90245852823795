import * as React from 'react';

import { MANAGE_API_TOKENS_CONTAINER } from 'src/ui/constants/testIds';
import { VoiceboxApiUsage } from 'src/ui/containers/api-tokens/VoiceboxApiUsage';
import { VoiceboxApps } from 'src/ui/containers/api-tokens/VoiceboxApps';

export const ManageApiTokens: React.VFC = () => {
  return (
    <div
      className="manage-api-tokens-container"
      data-testid={MANAGE_API_TOKENS_CONTAINER}
    >
      <VoiceboxApiUsage />
      {/* Hiding UserApiTokens for now VET-4828 PR #997 */}
      {/* <UserApiTokens /> */}
      <VoiceboxApps />
    </div>
  );
};
