import * as React from 'react';
import { numericSort } from 'vet-bones/utils';
import { useDidValueChange, usePrevious } from 'vet-bones/utils/hooks';
import { SelectItem } from 'vet-bones/utils/select';
import { removeDirective } from 'vet-bones/utils/voicebox';

import { graphQLClient } from 'src/ui/graph/graphQLClient';
import { useListVoiceboxConversationsQuery } from 'src/ui/graph/types';
import { useVoiceboxRouteParams } from 'src/ui/hooks/navbar/routes';

export interface ConversationSelectItem extends SelectItem<null> {
  connectionId: string;
  database: string;
  lastUpdated: number;
  userMessageCount: number;
}
export const useVoiceboxConversations = (connectionId: string) => {
  const { conversation: conversationId } = useVoiceboxRouteParams() || {};

  const prevConversation = usePrevious(conversationId);
  const didConversationChange = useDidValueChange(conversationId);
  const needsRefetch =
    !prevConversation && didConversationChange && conversationId;

  const { data, isFetching, refetch } = useListVoiceboxConversationsQuery(
    graphQLClient
  );

  React.useEffect(() => {
    if (needsRefetch) {
      refetch();
    }
  }, [needsRefetch, refetch]);

  const items = React.useMemo(() => {
    const conversationItems: ConversationSelectItem[] = [];
    /* istanbul ignore if */
    if (!connectionId) {
      return conversationItems;
    }

    data?.listVoiceboxConversations?.forEach((conversation) => {
      if (!conversation?.last_user_message) {
        return;
      }

      let text = conversation.name;
      if (!text) {
        text = removeDirective(conversation.first_user_message?.content || '');
      }

      const conversationConnectionId =
        conversation.last_user_message.user_message_context?.connection_id;

      if (
        !conversationConnectionId ||
        conversationConnectionId !== connectionId
      ) {
        return;
      }

      const createdDate = new Date(conversation.created);
      const createdMs = conversation.created ? createdDate.getTime() : null;

      const lastUserMessageDate = new Date(
        conversation.last_user_message.created
      );
      const lastUserMessageMs = conversation.last_user_message.created
        ? lastUserMessageDate.getTime()
        : null;
      const lastUpdated =
        (Number.isNaN(lastUserMessageMs) ? createdMs : lastUserMessageMs) || 0;
      const database =
        conversation.last_user_message.user_message_context?.database || '';

      conversationItems.push({
        connectionId,
        database,
        lastUpdated,
        text,
        value: conversation.id,
        userMessageCount: conversation.number_user_messages || 0,
      });
    });

    return conversationItems.sort(
      numericSort((item) => item.lastUpdated, true)
    );
  }, [connectionId, data]);

  return { items, isFetching };
};
